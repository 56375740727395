<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-10 md:pt-40 md:pb-16">
        <!-- Hero content -->
        <div class="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">
          <!-- Content -->
          <div
            class="md:col-span-7 lg:col-span-7 mb-8 md:mb-0 text-center md:text-left"
          >
            <h1
              class="h1 lg:text-6xl mb-4 font-red-hat-display font-black"
              data-aos="fade-down"
            >
              {{ $t("hero.title") }}
            </h1>
            <p
              class="text-xl text-gray-600 dark:text-gray-400"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              {{ $t("hero.subtitle") }}
            </p>
            <!-- <div class="flex justify-center md:justify-start">
              <div class="mt-8 flex space-x-4">
                <AppStoreDownloadButton
                  v-if="(OSName && !OSName.startsWith('Android')) || !OSName"
                  url="https://apps.apple.com/ca/app/many-penny/id6444689768"
                />
                <PlayStoreDownloadButton
                  v-if="(OSName && !OSName.startsWith('iOS')) || !OSName"
                  url="https://play.google.com/store/apps/details?id=ca.manypenny"
                />
              </div>
            </div> -->
            <ul
              class="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 -mb-2"
              data-aos="fade-down"
              data-aos-delay="450"
            >
              <li class="flex items-center mb-2">
                <svg
                  class="w-3 h-3 fill-current text-manypenny-tertiary-dark mr-2 shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                  />
                </svg>
                <span>{{ $t("hero.features.one") }}</span>
              </li>
              <li class="flex items-center mb-2">
                <svg
                  class="w-3 h-3 fill-current text-manypenny-tertiary-dark mr-2 shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                  />
                </svg>
                <span>{{ $t("hero.features.two") }}</span>
              </li>
              <li class="flex items-center mb-2">
                <svg
                  class="w-3 h-3 fill-current text-manypenny-tertiary-dark mr-2 shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                  />
                </svg>
                <span>{{ $t("hero.features.three") }}</span>
              </li>
            </ul>
          </div>
          <MobileMockup />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UAParser from "ua-parser-js";

import AppStoreDownloadButton from "../components/AppStoreDownloadButton.vue";
import MobileMockup from "./MobileMockup.vue";
import PlayStoreDownloadButton from "../components/PlayStoreDownloadButton.vue";

const parser = new UAParser();
let osName = "";

try {
  osName = parser.getOS().name;
} catch (err) {
  console.log("Couldn't determine OS name");
}

export default {
  name: "HeroHome",
  components: {
    AppStoreDownloadButton,
    MobileMockup,
    PlayStoreDownloadButton,
  },
  setup() {
    return {
      OSName: osName,
    };
  },
};
</script>
